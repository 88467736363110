/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-10-13",
    versionChannel: "nightly",
    buildDate: "2024-10-13T00:06:18.323Z",
    commitHash: "27182b34439a4599821801d96b3c88f915938da6",
};
